import React, { useState, useEffect } from "react"
import isDate from "validator/lib/isDate"

import { Link } from "gatsby-theme-material-ui";

import axios from 'axios';
import moment, { locale } from 'moment';
import 'moment/locale/es'

import { useQueryParam, StringParam } from "use-query-params";

import { StaticImage } from 'gatsby-plugin-image';

import '../styles/report.scss';

import Score from "../components/score";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinearProgressCustom from "../components/loading";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Container, Typography, Button, Box, Paper, Alert } from "@mui/material";

let formatter = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
});

let addressFields = {
  direccion: 'Calle y Número',
  coloniaPoblacion: 'Colonia',
  delegacionMunicipio: 'Del/Mpio',
  ciudad: 'Ciudad',
  estado: 'Estado',
  CP: 'C.P.',
  numeroTelefono: 'Teléfono',
  pais: 'País'
}

let jobsFields = {
  nombreEmpresa: 'Compañia',
  puesto: 'puesto',
  salarioMensual: 'Salario',
  direccion: 'Calle y Número',
  coloniaPoblacion: 'Colonia',
  ciudad: 'Ciudad',
  estado: 'Estado',
  CP: 'C.P.',
  numeroTelefono: 'Teléfono',
  pais: 'Pais',
  fechaContratacion: 'Fecha'
}

let banksFields = {
  nombreOtorgante: 'Otorgante',
  fechaActualizacion: 'Actualizado',
  saldoActual: 'Saldo actual',
  pagoActual: "Estatus de pago",
  frecuenciaPagos: 'Frecuencia de pagos',
  estadoDeCuenta: 'Estatus de la cuenta',
  fechaCierreCuenta: 'Fecha de eliminación',
  comportamiento: 'Comportamiento'
}

let consultasFields = {
  nombreOtorgante: 'Institucion',
  fechaConsulta: 'Fecha de Consulta',
  telefonoOtorgante: 'Telefono'
}

let banksDetailsFields = {
  nombreOtorgante: "Otorgante",
  fechaAperturaCuenta: "Apertura",
  fechaUltimoPago: "Ultimo pago",
  fechaCierreCuenta: "Cierre",
  claveUnidadMonetaria: "Moneda",
  limiteCredito: "Limite de crédito",
  creditoMaximo: "Crédito máximo",
  saldoActual: "Saldo actual",
  montoPagar: "Monto a pagar",
  frecuenciaPagos: "Periodo de pagos",
  historicoPagos: "Historico de pagos"
}

let currencyFields = [
  "limiteCredito",
  "saldoActual",
  "montoPagar",
  "montoUltimoPago",
  "creditoMaximo"
]

let comportamientoIcons = {
  "good": <Box role="good"><SentimentSatisfiedAltIcon /></Box>,
  "regular": <Box role="regular"><SentimentNeutralIcon /></Box>,
  "bad": <Box role="bad"><SentimentVeryDissatisfiedIcon /></Box>
}

const Table = (props) => {
  let { data = [], fields = {}, responsiveFields = [0, 1, 2], currencyFields = [] } = props;
  let fieldKeys = Object.keys(fields);
  return (
    <Box className="wrapper-table">
      {
        data
          ?
          <>
            <table className="table">
              <thead>
                <tr>
                  <th />
                  {
                    fieldKeys.map((field, i) => {
                      return (
                        <Box sx={{ display: responsiveFields?.length > 0 ? responsiveFields?.filter(x => x === i)?.length ? { xs: 'table-cell' } : { xs: 'none', md: 'table-cell' } : "table-cell" }} component="th" key={i}>{fields[field]}</Box>
                      )
                    })
                  }
                </tr>
              </thead>

              {
                data?.length
                  ?
                  <tbody>
                    {
                      data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            {
                              fieldKeys?.map((field, i) => {
                                // console.log('cell ', item[field])
                                return (
                                  <Box sx={{ fontWeight: 'normal', display: responsiveFields?.length > 0 ? responsiveFields?.filter(x => x === i)?.length ? { xs: 'table-cell' } : { xs: 'none', md: 'table-cell' } : "table-cell" }} component="th" key={i}>
                                    {
                                      item[field]
                                        ? currencyFields.find(compare => compare === field)
                                          ? formatter.format(item[field])
                                          : isDate(item[field])
                                            ? moment(item[field]).format('DD/MMM/YYYY').toUpperCase()
                                            : item[field]
                                        : item[field]
                                    }
                                  </Box>
                                )
                              })
                            }
                          </tr>
                        )
                      })
                    }
                  </tbody>
                  : null
              }
            </table>
            {
              data?.length === 0
                ?
                <Box
                  sx={{
                    py: 3,
                    px: 1.5
                  }}><Typography variant="body2">Con los datos proporcionados en la captura, no se encontró información.</Typography></Box>
                : null
            }
          </>
          : <Box><Typography variant="body2">No hay contenido para mostrar.</Typography></Box>
      }

    </Box>
  )
}

const PaymentsHistory = (props) => {
  let { data, dateReport = moment().format() } = props;

  const compare = (item) => {
    let period = 0
    if (item === " V") {
      period = 1
    } else if (item === "--") {
      period = "-"
    } else if (item !== "V" || item !== "--") {
      period = handleComportamiento(item)
    } else {
      period = "U"
    }
    return period;
  }

  const handleComportamiento = (item) => {
    let periods = {
      A: 365,
      B: 60,
      C: 14,
      D: 7,
      E: 180,
      M: 30,
      Q: 15,
      R: 30,
      S: 7,
      T: 120,
      U: 1
    }
    let status = 1;
    let days = periods[data?.frecuenciaPagos] * parseInt(item);

    if (days >= 1 && days <= 29) {
      status = 2
    } else if (days >= 30 && days <= 59) {
      status = 3
    } else if (days >= 60 && days <= 89) {
      status = 4
    } else if (days >= 90 && days <= 119) {
      status = 5
    } else if (days >= 120 && days <= 149) {
      status = 6
    } else if (days >= 150 && days <= 365) {
      status = 7
    } else if (days > 365) {
      status = 9
    }

    return status;
  }

  const handleString = (values) => {
    let newHistory = [];
    let count = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46, 48, 50]

    count?.map((option, i) => {
      if (i < values?.length / 2) {
        newHistory.push(values.substring(option - 2, option))
      }
    })

    return newHistory;
  }

  const handleRows = (year, month, index) => {
    let items = []

    if (index !== 0) {
      for (let i = 0; i < (12 - year?.length); i++) {
        items.push(<th key={i}></th>)
      }

    } else {
      for (let i = 0; i < (12 - year?.length) - (12 - month); i++) {
        items.push(<th key={i}></th>)
      }
    }


    year?.map((e, j) => {
      let item = compare(year[j])
      items.push(
        <th
          key={item}
          className={item >= 2 && item <= 4 ? "yellow" : item >= 5 && item <= 9 ? "red" : "white"}
        >{item}</th>
      )
    })
    return items
  }

  const handleYears = (credit) => {
    let close = credit?.fechaCierreCuenta ? credit?.fechaCierreCuenta : moment(dateReport).format("YYYY-MM-DD");
    let years = [
      moment(close).subtract(0, 'y').format('YYYY'),
      moment(close).subtract(1, 'y').format('YYYY'),
      moment(close).subtract(2, 'y').format('YYYY'),
    ]
    return years
  }

  return (
    <table className="calendar-wrapper">
      <thead>
        <tr>
          {
            ["Mes", "E", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"].map(month => {
              return (
                <th key={month} className="white">{month}</th>
              )
            })
          }
        </tr>
      </thead>
      <tbody>
        {
          handleYears(data)?.map((year, i) => {
            let values = handleString(data?.historicoPagos)
            let monthClose = data?.fechaCierreCuenta ? data?.fechaCierreCuenta : moment(dateReport).format("YYYY-MM-DD")
            let month = (moment(monthClose).format('MM') * 1)
            let valuesYear = [
              values?.slice(0, month).reverse(),
              values?.slice(month, month + 12).reverse(),
              values?.slice(month + 12, month + 12 + 12).reverse()
            ]

            return (
              <Box component="tr" key={i}>
                <th className="white">{year}</th>
                {
                  handleRows(valuesYear[i], month, i)
                }
              </Box>
            )
          })
        }
      </tbody>
    </table>
  )
}

const ReportPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState(null);
  const [report, setReport] = useState(null);
  const [resumen, setResumen] = useState({
    score: 0,
    deudaMensual: 0,
    deudaTotal: 0,
    endeudamiento: 0,
    consultas: 0,
    totalCuentas: 0,
    antiguedad: 0,
    estado: {
      bueno: 0,
      atraso: 0,
      malo: 0
    }
  });

  const [idReport] = useQueryParam("id", StringParam);

  useEffect(() => {
    // console.log('id: ', idReport)
    // console.log('reporte: ', report)

    if (report === null) {
      if (idReport) {
        handleCredit()
      }
    } else {
      setLoading(false)

      let antiguedadCuentas = moment().format();
      let deudaTotal = 0;
      let deuda = 0;
      let totalConsultas = 0;
      let contadorEstados = {
        bueno: 0,
        atraso: 0,
        malo: 0
      };

      let salario = report?.persona?.salario;

      report?.creditos?.map((credit, i) => {
        let estadoCredit = handleComportamiento(credit)
        deuda += handleDeuda(credit?.montoPagar || 0, credit?.frecuenciaPagos);
        deudaTotal += credit?.saldoActual * 1;
        antiguedadCuentas = credit?.fechaAperturaCuenta < antiguedadCuentas ? credit?.fechaAperturaCuenta : antiguedadCuentas;

        contadorEstados = {
          bueno: estadoCredit == "good" ? contadorEstados?.bueno + 1 : contadorEstados?.bueno,
          atraso: estadoCredit == "regular" ? contadorEstados?.atraso + 1 : contadorEstados?.atraso,
          malo: estadoCredit == "bad" ? contadorEstados?.malo + 1 : contadorEstados?.malo,
        }
      });

      report?.consultas?.map(consulta => {
        totalConsultas += moment().diff(consulta?.fechaConsulta, 'months') < 6 ? 1 : 0;
      });

      let porcentajeEndeudamiento = (deuda ? (deuda / salario) * 100 : 0).toFixed(1);

      setResumen({
        ...resumen,
        score: report?.scores[0]?.valor,
        deudaMensual: formatter.format(deuda),
        deudaTotal: formatter.format(deudaTotal),
        endeudamiento: porcentajeEndeudamiento,
        totalCuentas: report?.creditos?.length,
        antiguedad: antiguedadCuentas,
        consultas: totalConsultas,
        estado: contadorEstados
      })

    }

  }, [report])

  const handleCredit = async () => {
    setLoading(true)

    let token = '123456';

    let config = {
      headers: {
        'x-access-token': token
      },
      onDownloadProgress: e => {
        let time = (e.loaded * 100) / e.total;
        setProgress(time)
        // console.log('progress... ', time)
        // console.log('tets', e.lengthComputable)
      }
    }

    let response = await axios.get(
      `/api/v1/credito/${idReport}`,
      config
    )

    if (response?.data?.status) {
      setErrors(response?.data?.message)
    } else {
      setReport(response?.data)
    }

    // console.log('respuesta', response?.data)
  }

  const handleCapacidadDePago = () => {
    let value = 0;
    let salario = report?.persona?.salario;
    salario = salario / 2;
    let capacidad = 0;
    let deuda = 0;

    report?.creditos.map((credit, i) => {
      deuda += handleDeuda(credit?.montoPagar || 0, credit?.frecuenciaPagos);
      // deuda += Number(credit?.montoPagar);
    });

    console.log("Deuda ", deuda)

    if (report?.servicios?.proposito == "Obtener liquidez") {
      capacidad = salario;
    } else {
      capacidad = salario - deuda
    }

    value = capacidad > 0 ? capacidad * 100 : 0;

    value = formatter.format(value);
    return value
  }

  const handleDeuda = (monto, pagos) => {
    let periodo = {
      A: 0.08,
      B: 0.5,
      C: 2,
      D: 1,
      E: 0.01,
      M: 1,
      Q: 2,
      R: 1,
      S: 4,
      T: 0.33,
      U: 1
    }

    let total = Number(monto) * (periodo[pagos] || 1)
    return total
  }

  const handleComportamiento = (credit) => {
    let periods = {
      A: 365,
      B: 60,
      C: 14,
      D: 7,
      E: 180,
      M: 30,
      Q: 15,
      R: 30,
      S: 7,
      T: 120,
      U: 1
    }
    let status = "good";
    if (credit?.numeroPagosVencidos) {
      let days = periods[credit?.frecuenciaPagos] * credit?.peorAtraso;

      if (credit?.peorAtraso) {
        status = days >= 1 && days < 90 ? "regular" : "bad"
      }
    }

    return status;
  }

  const handleTipoCreditos = (value) => {
    let tipoCreditos = {
      AA: "Arrendamiento Automotriz",
      AB: "Automotriz Bancario",
      AE: "Física Actividad Empresarial",
      AM: "Aparatos/Muebles",
      AR: "Arrendamiento",
      AV: "Aviación",
      BC: "Banca Comunal",
      BL: "Bote/Lancha",
      BR: "Bienes Raíces",
      CA: "Compra De Automóvil",
      CC: "Crédito Al Consumo",
      CF: "Crédito Fiscal",
      CO: "Consolidación",
      CP: "Crédito Personal Al Consumo",
      ED: "Editorial",
      EQ: "Equipo",
      FF: "Fondeo Fira",
      FI: "Fianza",
      FT: "Factoraje",
      GS: "Grupo Solidario",
      HB: "Hipotecario Bancario",
      HE: "Préstamo Tipo Home Equity",
      HV: "Hipotecario ó Vivienda",
      LC: "Línea de Crédito",
      MC: "Mejoras a la Casa",
      NG: "Préstamo No Garantizado",
      PB: "Préstamo Personal Bancario",
      PC: "Procampo",
      PE: "Préstamo Para Estudiante",
      PG: "Préstamo Garantizado",
      PQ: "Préstamo Quirografario",
      PM: "Préstamo Empresarial",
      PN: "Préstamo de Nómina",
      PP: "Préstamo Personal",
      SH: "Segunda Hipoteca",
      TC: "Tarjeta De Crédito",
      TD: "Tarjeta Departamental",
      TG: "Tarjeta Garantizada",
      TS: "Tarjeta De Servicios",
      VR: "Vehículo Recreativo",
      OT: "Otros",
      NC: "Desconocido",
    }
    return (tipoCreditos[value])
  }

  const handleTipoCuenta = (value) => {
    let tipoCuenta = {
      F: "Pagos fijos",
      H: "Hipoteca",
      L: "Sin límite preestablecido",
      R: "Revolvente",
      Q: "Quirografario",
      A: "Crédito de habilitación o avío",
      E: "Crédito refaccionario",
      P: "Crédito prendario"
    }
    return (tipoCuenta[value])
  }

  const handleTipoResponsabilidad = (value) => {
    let tipoResponsabilidad = {
      I: "Individual(Titular)",
      M: "Mancomunado",
      O: "Obligatorio Solidario",
      A: "Aval",
      T: "Titular con Aval"
    }
    return (tipoResponsabilidad[value])
  }

  const handleFrecuenciaPagos = (value) => {
    let periods = {
      A: "Anual",
      B: "Bimestral",
      C: "Catorcenal",
      D: "Deducción de Salario",
      E: "Semestral",
      M: "Mensual",
      Q: "Quincenal",
      R: "Pago Mínimo Para Cuentas Revolventes",
      S: "Semanal",
      T: "Trimestral",
      U: "Una Sola Exhibición"
    }
    return periods[value]
  }

  const handleOtorgante = (credit) => {
    let item =
      <Typography
        variant="body2"
        sx={{ textAlign: 'left', fontSize: '12px' }}
      >
        <b>{credit?.nombreOtorgante}</b>
        <br /> {handleTipoCreditos(credit?.tipoCredito)}
        <br /> {handleTipoCuenta(credit?.tipoCuenta)}
        <br /> {handleTipoResponsabilidad(credit?.tipoResponsabilidad)}
      </Typography>
    return item;
  }

  return (
    <>
      {
        !loading
          ?
          <Container maxWidth="xl" sx={{ py: 2 }}>
            <Button component={Link} to="/admin" variant="secondary" startIcon={<ArrowBackIcon />}>Regresar</Button>
            <Box className="wrapper-report">
              <Box className="report-folio">
                <h3>Folio de consulta</h3>
                <p>{report?.folioConsulta}</p>
              </Box>

              <h1>Reporte de crédito</h1>
              <h3>Resumen</h3>

              <Paper
                className="general-report general-report-text"
                elevation={0}
                sx={{
                  flexWrap: { xs: "wrap", sm: "nowrap" }
                }}
              >
                <p className="report-name"><b>{report?.persona?.primerNombre} {report?.persona?.segundoNombre} {report?.persona?.apellidoPaterno} {report?.persona?.apellidoMaterno}</b></p>
                <p>Fecha de consulta: <b>{moment(report?.consultas[0]?.fechaConsulta).format('ll')}</b></p>
                <p>Fecha de registro: <b>{moment(resumen?.antiguedad).format('ll')}</b></p>
              </Paper>

              <Paper
                className="general-report"
                elevation={0}
                sx={{
                  flexWrap: { xs: "wrap", md: "nowrap" }
                }}
              >
                <Paper className="bc-score" p={2} elevation={0}>
                  <p className="title">Calificacion crediticia:</p>

                  <Score score={resumen?.score} />

                </Paper>

                <Paper className="general-metrics" elevation={0}>
                  <Box className="metrics-box">
                    <p><b>Deuda Mensual</b></p>
                    <p className="metrics-total">{resumen?.deudaMensual}</p>
                  </Box>

                  <Box className="metrics-box">
                    <p><b>Deuda Total</b></p>
                    <p className="metrics-total">{resumen?.deudaTotal}</p>
                  </Box>

                  <Box className="metrics-box">
                    <p><b>Endeudamiento</b></p>
                    <p className="metrics-total">{resumen?.endeudamiento}%</p>
                  </Box>

                  <Box className="metrics-box">
                    <Box
                      sx={{
                        flexGrow: 1
                      }}
                    >
                      <p role="good"><SentimentSatisfiedAltIcon /><b>{resumen?.estado?.bueno}</b> Cuentas con BUEN estado</p>
                      <p role="regular"><SentimentNeutralIcon /><b>{resumen?.estado?.atraso}</b> Cuentas con ATRASO moderado</p>
                      <p role="bad"><SentimentVeryDissatisfiedIcon /><b>{resumen?.estado?.malo}</b> Cuentas con MAL estado</p>
                    </Box>
                    <Box
                      sx={{
                        flexGrow: 1
                      }}
                    >
                      <p><b>{resumen?.consultas}</b> Consultas a Buró en los útimos 6 meses</p>
                      <p><b>{resumen?.totalCuentas}</b> Cuentas registradas</p>
                      <p><b>{moment().diff(resumen?.antiguedad, 'years')}</b> años de antiguedad crediticia</p>
                    </Box>
                  </Box>
                </Paper>
              </Paper>

              <h3>Precalificación</h3>

              <Paper className="general-report general-report-text" elevation={0}>
                <p>Salario: <b>{formatter.format(report?.persona?.salario)}</b></p>
                <p>Propósito: <b>{report?.servicios?.proposito}</b></p>
                <p>Inmueble: <b>{report?.servicios?.valor}</b></p>
                <p>Crédito preautorizado: <b>{handleCapacidadDePago()}</b></p>
              </Paper>

              <h3>Datos Personales</h3>

              <Paper className="general-report general-report-text" elevation={0}>
                <p className="report-name">Nombre: <b>{report?.persona?.primerNombre} {report?.persona?.segundoNombre} {report?.persona?.apellidoPaterno} {report?.persona?.apellidoMaterno}</b></p>
                <p>Fecha de Nacimiento: <b>{moment(report?.persona?.fechaNacimiento).format('ll')}</b></p>
                <p>RFC: <b>{report?.persona?.RFC}</b></p>
                <p>Celular: <b>{report?.contacto?.celular}</b></p>
                <p>Correo: <b>{report?.contacto?.correo}</b></p>
              </Paper>

              <Paper elevation={0}>
                <h3>Domicilio(s) Reportado</h3>
                <Table
                  data={report?.domicilios}
                  fields={addressFields}
                  responsiveFields={[]}
                />
              </Paper>


              <Paper elevation={0}>
                <h3>Datos de Empleos</h3>
                <Table
                  data={report?.empleos}
                  fields={jobsFields}
                  currencyFields={["salarioMensual"]}
                />
              </Paper>

              <h2>Resumen de Creditos</h2>

              <Paper elevation={0}>
                <h3>Créditos Bancarios</h3>
                <Table
                  data={
                    report?.creditos?.filter(row => ['TC', 'AB'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        estadoDeCuenta: !credit?.fechaCierreCuenta ? 'Activo' : 'Cerrada',
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        comportamiento: comportamientoIcons[handleComportamiento(credit)],
                        pagoActual: credit?.pagoActual === " V" ? "Al corriente" : credit?.pagoActual === "--" ? "No reportado" : `Atraso de ${credit?.pagoActual * 1} pagos`
                      };
                    })
                  }
                  fields={banksFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <Paper elevation={0} className="info">
                <Typography mb={2} component="h6" variant="h6">Información sobre el comportamiento</Typography>
                <Typography mb={2} variant="body2">El comportamiento de cada una de sus cuentas, tanto activas como cerradas, se traduce en un ícono en el extremo derecho de cada sección, este ícono le ayudará a identificar clara y rápidamente la situación crediticia de sus cuentas.</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    flexWrap: { xs: 'wrap', sm: 'nowrap' }
                  }}
                >
                  <Box role="good"><SentimentSatisfiedAltIcon /> <Typography component="b" variant="subtitle2">Cuenta al corriente</Typography></Box>
                  <Box role="regular"><SentimentNeutralIcon /> <Typography component="b" variant="subtitle2">Atraso de 1 A 89 días</Typography></Box>
                  <Box role="bad"><SentimentVeryDissatisfiedIcon /> <Typography component="b" variant="subtitle2">Atraso mayor a 90 días o deuda sin recuperar</Typography></Box>
                </Box>
              </Paper>

              <Paper elevation={0}>
                <h3>Créditos no Bancarios</h3>
                <Table
                  data={
                    report?.creditos?.filter(row => !['TC', 'AB', 'BR', 'LC'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        estadoDeCuenta: !credit?.fechaCierreCuenta ? 'Activo' : 'Cerrada',
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        comportamiento: comportamientoIcons[handleComportamiento(credit)],
                        pagoActual: credit?.pagoActual === " V" ? "Al corriente" : credit?.pagoActual === "--" ? "No reportado" : `Atraso de ${credit?.pagoActual * 1} pagos`
                      };
                    })
                  }
                  fields={banksFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <Paper elevation={0}>
                <h3>Créditos personas morales</h3>
                <Table
                  data={
                    report?.creditos?.filter(row => ['BR'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        estadoDeCuenta: !credit?.fechaCierreCuenta ? 'Activo' : 'Cerrada',
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        comportamiento: comportamientoIcons[handleComportamiento(credit)],
                        pagoActual: credit?.pagoActual === " V" ? "Al corriente" : credit?.pagoActual === "--" ? "No reportado" : `Atraso de ${credit?.pagoActual * 1} pagos`
                      };
                    })
                  }
                  fields={banksFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <Paper elevation={0}>
                <h3>Créditos tiendas</h3>
                <Table
                  data={
                    report?.creditos?.filter(row => ['LC'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        estadoDeCuenta: !credit?.fechaCierreCuenta ? 'Activo' : 'Cerrada',
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        comportamiento: comportamientoIcons[handleComportamiento(credit)],
                        pagoActual: credit?.pagoActual === " V" ? "Al corriente" : credit?.pagoActual === "--" ? "No reportado" : `Atraso de ${credit?.pagoActual * 1} pagos`
                      };
                    })
                  }
                  fields={banksFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <h2>Detalles de Creditos</h2>

              <Paper elevation={0}>
                <h3>Créditos Bancarios</h3>
                <Table
                  data={
                    // report?.creditos.filter(credit => credit?.tipoCredito === 'TC')
                    report?.creditos?.filter(row => ['TC', 'AB'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        historicoPagos: <PaymentsHistory dateReport={report?.consultas[0]?.fechaConsulta} data={credit} />
                      };
                    })
                  }
                  fields={banksDetailsFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <Paper elevation={0}>
                <h3>Créditos no Bancarios</h3>
                <Table
                  data={
                    report?.creditos?.filter(row => !['TC', 'AB', 'BR', 'LC'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        historicoPagos: <PaymentsHistory dateReport={report?.consultas[0]?.fechaConsulta} data={credit} />
                      };
                    })
                  }
                  fields={banksDetailsFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <Paper elevation={0}>
                <h3>Créditos personas morales</h3>
                <Table
                  data={
                    report?.creditos?.filter(row => ['BR'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        historicoPagos: <PaymentsHistory dateReport={report?.consultas[0]?.fechaConsulta} data={credit} />
                      };
                    })
                  }
                  fields={banksDetailsFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <Paper elevation={0}>
                <h3>Créditos tiendas</h3>
                <Table
                  data={
                    report?.creditos?.filter(row => ['LC'].filter(t => row?.tipoCredito === t)?.length)?.map(credit => {
                      return {
                        ...credit,
                        nombreOtorgante: handleOtorgante(credit),
                        frecuenciaPagos: handleFrecuenciaPagos(credit?.frecuenciaPagos),
                        historicoPagos: <PaymentsHistory dateReport={report?.consultas[0]?.fechaConsulta} data={credit} />
                      };
                    })
                  }
                  fields={banksDetailsFields}
                  responsiveFields={[]}
                  currencyFields={currencyFields} />
              </Paper>

              <Paper elevation={0}>
                <Typography mb={4} component="h6" variant="h6">Nomenclatura de Historico de Pagos</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: 2 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'white',
                          border: '1px solid gray',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>0</b>Cuenta muy reciente para ser informada</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'white',
                          border: '1px solid gray',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>1</b>Cuenta al corriente</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'orange',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>2</b>Cuenta con atraso de 1 a 29 dias</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'orange',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>3</b>Cuenta con atraso de 30 a 59 dias</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'orange',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>4</b>Cuenta con atraso de 60 a 89 dias</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'red',
                          color: 'white',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>5</b>Cuenta con atraso de 90 a 119 dias</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'red',
                          color: 'white',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>6</b>Cuenta con atraso de 120 a 149 dias</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'red',
                          color: 'white',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>7</b>Cuenta con atraso de 150 días a 12 meses</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'red',
                          color: 'white',
                          width: '30px',
                          textAlign: 'center',
                          alignSelf: 'start',
                          py: .5
                        }
                      }}><b>9</b>Puede significar: <br />- Cuenta con atraso de más de 12 meses <br /> -Fraude cometido por el consumidor <br />-Cuenta con deuda parcial o total sin recuperar </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'white',
                          border: '1px solid gray',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>U</b>Cuenta sin información</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'white',
                          border: '1px solid gray',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>-</b>Cuenta no reportada en este periodo</Typography>
                      <Typography variant="body2" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        mb: 1,
                        '& b': {
                          background: 'white',
                          border: '1px solid gray',
                          width: '30px',
                          textAlign: 'center',
                          py: .5
                        }
                      }}><b>D</b>Información anulada</Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>

              <h2>Detalles de Consultas</h2>

              <Paper elevation={0}>
                <h3>Detalles de Consultas</h3>
                <Table
                  data={
                    report?.consultas?.map(consulta => {
                      return ({
                        ...consulta,
                        nombreOtorgante: <Typography sx={{ textAlign: 'left', fontSize: "12px" }}>{consulta?.nombreOtorgante}</Typography>
                      })
                    })
                  }
                  fields={consultasFields}
                  responsiveFields={[]}
                />
              </Paper>

            </Box>
          </Container>
          :
          errors
            ? <Alert severity='error'>{errors}</Alert>
            :
            <Box className="wrapper-loading">
              <Box className="spinner">

                <LinearProgressCustom progress={progress} />

              </Box>

              <Box>
                <h3>Buscando reporte...</h3>
              </Box>
            </Box>
      }
    </>
  )
}

export default ReportPage
